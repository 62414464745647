import { useContext, useEffect, useRef, useState } from 'react';

import {
  EnumCountries,
  //EnumCountries,
  EnumTypeDownloadFile,
  NameIcons,
  SizeScreen,
  StyleButton,
  TypeAlertMessage,
  TypeDelivery,
  TypeToast,
  EnumTextPrice
} from 'configs/Enums';
import { VALIDATE_CONSTRAINTS } from 'configs/Constants';
import { ResponseApp } from 'configs/Interface';

import { DataContext } from 'context/Data';
import { RootContext } from 'context/RootContext';

import ButtonCustom from 'components/atoms/buttons';
import ButtonDownloadFile from 'components/atoms/buttons/ButtonDownloadFile';
import InputCustom from 'components/atoms/inputs';
import SelectCustom from 'components/atoms/selects';
import FormSessionLayout from 'components/molecules/layout/FormSession';
import RenderPopUp from 'components/molecules/modals/RenderPopUp';
import MenuSessionContainer from 'components/molecules/layout/MenuSessionContainer';

import FormManualOrder, { FormManualOrderModel } from 'models/order/FormManual';
import Delivery from 'models/Delivery';
import DocumentType from 'models/user/DocumentType';
import Dimension from 'models/Dimension';
import User from 'models/User';
import Order, { EnumFielOrder } from 'models/order';

import { isDisabledUnitManualLoad, isRequeridUnitManualLoad } from 'helpers/Validate';
import { nameDistrictForCountry, stringDeliveryToIcon } from 'helpers/Converts';
import IconCustom from 'components/atoms/icons';
import AlertMessage from 'components/molecules/messages/Alert';
import { AuthContext } from 'context/AuthContext';
import StoreSelectorModal from 'components/molecules/modals/StoreSelector';
import { Tooltip } from 'antd';

type UnitManualLoadOrganismProps = {
  country?: string;
  typesDelivery: Delivery[];
  dimensions: Dimension[];
  onSubmitOrder(order: FormManualOrder): Promise<ResponseApp>;
  typesDocument: DocumentType[];
  user?: User;
  orderNew?: Order;
  onUpdateOrderNew: (order?: Order) => void;
  onLoadCities(params: { country: string; typeDelivery: string }): Promise<string[]>;
  onLoadDistrictByCity(params: { country: string; typeDelivery: string; city: string; warehouse: string }): Promise<void>;
};

const UnitManualLoadOrganism = ({
  //districts,
  onSubmitOrder,
  typesDocument,
  dimensions,
  typesDelivery,
  user,
  orderNew,
  onUpdateOrderNew,
  onLoadCities,
  onLoadDistrictByCity
}: UnitManualLoadOrganismProps) => {
  const inpSelectWarehouse = useRef<any>(null);
  const inpSelectDelivery = useRef<any>(null);
  const inpNames = useRef<HTMLInputElement>(null);
  const inpLastName = useRef<HTMLInputElement>(null);
  const inpEmail = useRef<HTMLInputElement>(null);
  const inpPhone = useRef<HTMLInputElement>(null);
  const inpTypeDocument = useRef<any>(null);
  const inpNDocument = useRef<HTMLInputElement>(null);
  const inpCity = useRef<HTMLInputElement>(null);
  const inpDistrict = useRef<HTMLInputElement>(null);
  const inpDirection = useRef<HTMLInputElement>(null);
  const inpNumberStreet = useRef<HTMLInputElement>(null);
  const inpAddressDetail = useRef<HTMLInputElement>(null);
  const inpItem = useRef<HTMLInputElement>(null);
  const inpTotalItems = useRef<HTMLInputElement>(null);
  const inpAmount = useRef<HTMLInputElement>(null);
  const inpDimension = useRef<any>(null);

  const { showToast, screenResolution } = useContext(RootContext);
  const { countryUser } = useContext(AuthContext);
  const { warehouse } = useContext(DataContext);

  const [order, setOrder] = useState<FormManualOrderModel>();
  const [orderSummary, setOrderSummary] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const [messageError, setMessageError] = useState([]);
  const [listValidates, setListValidates] = useState<{ input: string; isValid: boolean }[]>([]);
  const [txtPrice, setTxtPrice] = useState('');

  const onValidateAllInputs = () => {
    setOrderSummary(true);
  };

  const onSubmit = async () => {
    onUpdateOrderNew(undefined);
    console.log('onSubmit() ==>', order);
    if (order) {
      console.log('onSubmit() ==>', order);
      const { status, data } = await onSubmitOrder(new FormManualOrder(order));
      if (!status) {
        setMessageError(await transformData(data)) as any;
        setOrderSummary(false);
        setOrderError(true);
      }
    } else {
      showToast({ text: 'No hay una orden para enviar.', type: TypeToast.info });
    }
  };

  const transformData = async (data: any) => {
    let errors: any = [];
    if (data.errorSchema) {
      errors = data.errors;
    } else {
      const errorMessages = Object.values(data.errors);

      errorMessages.forEach((value, index) => {
        const msg = value as string;
        if (msg.includes('Error:')) {
          errors.push({ path: [Object.keys(data.errors)[index]], message: msg.replace(/Error: |{{[^}]+}}/g, '') });
        }
      });
    }

    return errors;
  };

  const isAvailable = () => {
    console.log('isAvailable ==>', { result: listValidates.every(lv => lv.isValid), listValidates });
    return !listValidates.every(lv => lv.isValid);
  };

  const onUpdateValidated = ({
    isValid,
    value,
    type,
    types
  }: {
    isValid?: boolean;
    value?: any;
    type?: EnumFielOrder;
    types?: EnumFielOrder[];
  }) => {
    listValidates.forEach(lv => {
      if (types) {
        if (types.find(ty => ty === lv.input)) {
          lv.isValid = isValid || false;
        }
      } else if (lv.input === type) {
        lv.isValid = isValid || false;
      }
    });
    if (value) {
      console.log('onUpdateValidated() => value', { value, listValidates });
    }
    setListValidates(listValidates);
  };

  useEffect(() => {
    setListValidates([
      {
        input: EnumFielOrder.order,
        isValid: false
      },
      {
        input: EnumFielOrder.typeShipphing,
        isValid: false
      },
      {
        input: EnumFielOrder.warehouse,
        isValid: false
      },
      {
        input: EnumFielOrder.firstName,
        isValid: false
      },
      {
        input: EnumFielOrder.lastName,
        isValid: false
      },
      {
        input: EnumFielOrder.email,
        isValid: false
      },
      {
        input: EnumFielOrder.phone,
        isValid: false
      },
      {
        input: EnumFielOrder.documentType,
        isValid: countryUser === EnumCountries.cl ? true : false
      },
      {
        input: EnumFielOrder.numberDocument,
        isValid: countryUser === EnumCountries.cl ? true : false
      },
      {
        input: EnumFielOrder.city,
        isValid: false
      },
      {
        input: EnumFielOrder.district,
        isValid: false
      },
      {
        input: EnumFielOrder.adr,
        isValid: false
      },
      {
        input: EnumFielOrder.streetNumber,
        isValid: false
      },
      {
        input: EnumFielOrder.items,
        isValid: false
      },
      {
        input: EnumFielOrder.purchasedQuantity,
        isValid: false
      },
      {
        input: EnumFielOrder.purchasePrice,
        isValid: false
      },
      {
        input: EnumFielOrder.size,
        isValid: countryUser === EnumCountries.cl ? true : false
      }
    ]);
  }, []);

  return (
    <MenuSessionContainer>
      <div className='relative'>
        <div className='container flex flex-col justify-between flex-1 mx-auto mb-2 md:mb-4 xl:w-3/4 sm:flex-row'>
          <div className='flex'>
            <h1 className='text-2xl sm:text-3xl'>Datos envío unitario</h1>
            <div className='ml-6'>
              <StoreSelectorModal />
            </div>
          </div>

          <ButtonDownloadFile className='self-end my-2' type={EnumTypeDownloadFile.handbookManualUnitLoading} />
        </div>

        <div className='container px-8 pt-8 pb-4 mx-auto bg-white shadow-lg rounded-md xl:w-3/4'>
          <FormSessionLayout title='Datos pedido'>
            <InputCustom
              label='Orden'
              value={order ? order.order : undefined}
              placeholder='Orden'
              type='text'
              enterKeyHint='next'
              isRequired
              isIconRequired={false}
              onChange={event => setOrder({ ...order, order: event.target?.value || '' })}
              onPressEnter={() => inpSelectDelivery.current?.focus()}
              messageErrorTooltip={
                Order.isParameterInvalid(orderNew?.data.detail.data.order.data.order)
                  ? Order.removeFlag(orderNew?.data.detail.data.order.data.order)
                  : undefined
              }
              onValidate={(isValid, val) => onUpdateValidated({ isValid, value: val, type: EnumFielOrder.order })}
            />
            <SelectCustom
              ref={inpSelectWarehouse}
              label='Bodega'
              value={order ? order.warehouse : undefined}
              style={{ width: '100%' }}
              showSearch
              isRequired
              isIconRequired={false}
              data={warehouse.map(wareh => ({
                label: `${wareh.data.description}`,
                value: wareh.toString(),
                item: wareh
              }))}
              onChange={async value => {
                console.log('value ==>', value);
                setOrder({ ...order, warehouse: value, typeDelivery: undefined });
                if (inpNames && inpNames.current !== null) {
                  inpNames.current.focus();
                }
              }}
              messageErrorTooltip={
                Order.isParameterInvalid(orderNew?.data.warehouse) ? Order.removeFlag(orderNew?.data.warehouse) : undefined
              }
              onValidate={isValid => onUpdateValidated({ isValid, type: EnumFielOrder.warehouse })}
            />
            {order && order.warehouse && (
              <SelectCustom
                ref={inpSelectDelivery}
                label='Tipo de Envío'
                value={order ? order.typeDelivery : undefined}
                style={{ width: '100%' }}
                placeholder='Tipo de Envío'
                isRequired
                isIconRequired={false}
                data={
                  typesDelivery.length > 0
                    ? typesDelivery
                        .filter(delivery => delivery.data.warehouse === order?.warehouse?.data.id)
                        .map(delivery => ({
                          label: delivery.data.name,
                          value: delivery.toString(),
                          item: delivery
                        }))
                    : []
                }
                onChange={async value => {
                  console.log('value ==>', value);
                  let city = '';
                  const typeDelivery = value.data.value.toLowerCase();
                  if (typeDelivery === TypeDelivery.pickup || typeDelivery === TypeDelivery.pickupStore) {
                    setTxtPrice(EnumTextPrice.pickup);
                    if (order) {
                      order.city = '';
                      order.district = '';
                      order.direction = '';
                      order.numStreet = '';
                      order.department = '';
                    } else {
                      city = '';
                    }
                    onUpdateValidated({
                      isValid: true,
                      types: [EnumFielOrder.city, EnumFielOrder.district, EnumFielOrder.adr, EnumFielOrder.streetNumber]
                    });
                  } else {
                    setTxtPrice(EnumTextPrice.delivey);
                    if (user) {
                      const cities = await onLoadCities({ country: countryUser || '', typeDelivery: value.data.value });
                      console.log('onChange() ==>', { value, cities });
                      if (cities && cities.length === 1) {
                        city = cities[0];
                        onUpdateValidated({ isValid: true, type: EnumFielOrder.city });
                        onUpdateValidated({
                          isValid: false,
                          types: [EnumFielOrder.district, EnumFielOrder.adr, EnumFielOrder.streetNumber]
                        });
                        await onLoadDistrictByCity({
                          city: cities[0],
                          country: countryUser || '',
                          typeDelivery: value.data.value,
                          warehouse: order?.warehouse?.data.id || ''
                        });
                      } else {
                        onUpdateValidated({
                          isValid: false,
                          types: [EnumFielOrder.city, EnumFielOrder.district, EnumFielOrder.adr, EnumFielOrder.streetNumber]
                        });
                      }
                    }
                  }
                  console.log('onChange() ==>', { order, city });
                  setOrder({ ...order, typeDelivery: value || '', city });
                  if (inpSelectWarehouse && inpSelectWarehouse.current === null) {
                    inpSelectWarehouse.current.focus();
                  }
                }}
                messageErrorTooltip={
                  Order.isParameterInvalid(orderNew?.data.detail.data.shipphing.data.type)
                    ? Order.removeFlag(orderNew?.data.detail.data.shipphing.data.type)
                    : undefined
                }
                onValidate={isValid => onUpdateValidated({ isValid, type: EnumFielOrder.typeShipphing })}
              />
            )}
          </FormSessionLayout>

          <FormSessionLayout title='Datos cliente'>
            <InputCustom
              ref={inpNames}
              label='Nombre'
              value={order ? order.firstName : undefined}
              placeholder='Nombre'
              enterKeyHint='next'
              isRequired
              isIconRequired={false}
              validateConstraints={VALIDATE_CONSTRAINTS.name}
              onChange={event => {
                console.log('event ==>', event.target?.value);
                setOrder({ ...order, firstName: event.target?.value || '' });
              }}
              autoComplete='new-password'
              onPressEnter={() => {
                if (inpLastName && inpLastName.current !== null) {
                  inpLastName.current.focus();
                }
              }}
              messageErrorTooltip={
                Order.isParameterInvalid(orderNew?.data.detail.data.customer.data.firstName)
                  ? Order.removeFlag(orderNew?.data.detail.data.customer.data.firstName)
                  : undefined
              }
              onValidate={(isValid, val) => onUpdateValidated({ isValid, value: val, type: EnumFielOrder.firstName })}
            />
            <InputCustom
              ref={inpLastName}
              label='Apellido'
              value={order ? order.lastName : undefined}
              placeholder='Apellido'
              enterKeyHint='next'
              isRequired
              isIconRequired={false}
              validateConstraints={VALIDATE_CONSTRAINTS.name}
              onChange={event => setOrder({ ...order, lastName: event.target?.value || '' })}
              autoComplete='new-password'
              onPressEnter={() => {
                if (inpEmail && inpEmail.current !== null) {
                  inpEmail.current.focus();
                }
              }}
              messageErrorTooltip={
                Order.isParameterInvalid(orderNew?.data.detail.data.customer.data.lastName)
                  ? Order.removeFlag(orderNew?.data.detail.data.customer.data.lastName)
                  : undefined
              }
              onValidate={(isValid, val) => onUpdateValidated({ isValid, value: val, type: EnumFielOrder.lastName })}
            />
            <InputCustom
              ref={inpEmail}
              label='Email'
              value={order ? order.email : undefined}
              placeholder='Email'
              type='email'
              enterKeyHint='next'
              isRequired
              isIconRequired={false}
              validateConstraints={VALIDATE_CONSTRAINTS.email}
              onChange={event => setOrder({ ...order, email: event.target?.value || '' })}
              autoComplete='new-password'
              onPressEnter={() => {
                if (inpPhone && inpPhone.current !== null) {
                  inpPhone.current.focus();
                }
              }}
              messageErrorTooltip={
                Order.isParameterInvalid(orderNew?.data.detail.data.customer.data.email)
                  ? Order.removeFlag(orderNew?.data.detail.data.customer.data.email)
                  : undefined
              }
              onValidate={(isValid, val) => onUpdateValidated({ isValid, value: val, type: EnumFielOrder.email })}
            />
            <InputCustom
              ref={inpPhone}
              label='Teléfono'
              type='tel'
              value={order ? order.phone : undefined}
              placeholder='Teléfono'
              enterKeyHint='next'
              isRequired
              isIconRequired={false}
              validateConstraints={VALIDATE_CONSTRAINTS.phone}
              onChange={event => setOrder({ ...order, phone: event.target?.value || '' })}
              autoComplete='new-password'
              onPressEnter={() => {
                if (inpTypeDocument && inpTypeDocument.current !== null) {
                  inpTypeDocument.current.focus();
                }
              }}
              messageErrorTooltip={
                Order.isParameterInvalid(orderNew?.data.detail.data.customer.data.phone)
                  ? Order.removeFlag(orderNew?.data.detail.data.customer.data.phone)
                  : undefined
              }
              onValidate={(isValid, val) => onUpdateValidated({ isValid, value: val, type: EnumFielOrder.phone })}
            />
            <SelectCustom
              ref={inpTypeDocument}
              label={`Tipo de Documento ${
                isRequeridUnitManualLoad({
                  country: user?.data.country,
                  field: 'typeDocument',
                  typeDocument: order?.typeDocument
                })
                  ? ''
                  : '(opcional)'
              }`}
              value={order ? order.typeDocument : undefined}
              style={{ width: '100%' }}
              placeholder='Tipo de Documento'
              isRequired={isRequeridUnitManualLoad({
                country: user?.data.country,
                field: 'typeDocument',
                typeDocument: order?.typeDocument
              })}
              isIconRequired={
                !isRequeridUnitManualLoad({
                  country: user?.data.country,
                  field: 'typeDocument',
                  typeDocument: order?.typeDocument
                })
              }
              data={
                typesDocument.length > 0
                  ? typesDocument.map(documentType => ({
                      label: documentType.toString(),
                      value: documentType.data.value,
                      item: documentType
                    }))
                  : []
              }
              onChange={value => {
                console.log('onChange', value);
                setOrder({ ...order, typeDocument: value.data.value || '' });
                if (inpNDocument && inpNDocument.current !== null) {
                  inpNDocument.current.focus();
                }
              }}
              messageErrorTooltip={
                Order.isParameterInvalid(orderNew?.data.detail.data.customer.data.documentType)
                  ? Order.removeFlag(orderNew?.data.detail.data.customer.data.documentType)
                  : undefined
              }
              onValidate={isValid => onUpdateValidated({ isValid, type: EnumFielOrder.documentType })}
            />
            <InputCustom
              ref={inpNDocument}
              label={`Nº Documento ${
                isRequeridUnitManualLoad({
                  country: user?.data.country,
                  field: 'numDocument',
                  typeDocument: order?.typeDocument
                })
                  ? ''
                  : '(opcional)'
              }`}
              value={order ? order.numDocument : undefined}
              placeholder='Nº Documento'
              enterKeyHint='next'
              isRequired={isRequeridUnitManualLoad({
                country: user?.data.country,
                field: 'numDocument',
                typeDocument: order?.typeDocument
              })}
              isIconRequired={
                !isRequeridUnitManualLoad({
                  country: user?.data.country,
                  field: 'numDocument',
                  typeDocument: order?.typeDocument
                })
              }
              onChange={event => setOrder({ ...order, numDocument: event.target?.value || '' })}
              onPressEnter={() => {
                if (inpCity && inpCity.current !== null) {
                  inpCity.current.focus();
                }
              }}
              messageErrorTooltip={
                Order.isParameterInvalid(orderNew?.data.detail.data.customer.data.numberDocument)
                  ? Order.removeFlag(orderNew?.data.detail.data.customer.data.numberDocument)
                  : undefined
              }
              onValidate={(isValid, val) => {
                console.log('onValidate() =>', { isValid, val });
                onUpdateValidated({ isValid, value: val, type: EnumFielOrder.numberDocument });
              }}
              validateConstraints={VALIDATE_CONSTRAINTS.documentNum(order?.typeDocument, user?.data.country)}
            />

            {order &&
              order.typeDelivery &&
              order.typeDelivery.isApplyAddress() &&
              order.typeDelivery.data.cities &&
              order.typeDelivery.data.cities.length === 0 && (
                <InputCustom
                  ref={inpCity}
                  label='Ciudad'
                  type='text'
                  value={order ? order.city : undefined}
                  placeholder='Ciudad'
                  enterKeyHint='next'
                  isRequired={isRequeridUnitManualLoad({
                    typeDelivery: order?.typeDelivery?.data.value,
                    field: 'city',
                    country: user?.data.country
                  })}
                  isIconRequired={
                    !isRequeridUnitManualLoad({
                      typeDelivery: order?.typeDelivery?.data.value,
                      field: 'city',
                      country: user?.data.country
                    })
                  }
                  disabled={isDisabledUnitManualLoad({ typeDelivery: order?.typeDelivery?.data.value, field: 'city' })}
                  validateConstraints={VALIDATE_CONSTRAINTS.name}
                  onChange={event => setOrder({ ...order, city: event.target?.value || '' })}
                  autoComplete='new-password'
                  onPressEnter={() => {
                    if (inpDistrict && inpDistrict.current !== null) {
                      inpDistrict.current.focus();
                    }
                  }}
                  messageErrorTooltip={
                    Order.isParameterInvalid(orderNew?.data.detail.data.addresses.data.state)
                      ? Order.removeFlag(orderNew?.data.detail.data.addresses.data.state)
                      : undefined
                  }
                  onValidate={(isValid, val) => onUpdateValidated({ isValid, value: val, type: EnumFielOrder.city })}
                />
              )}

            {order &&
              order.typeDelivery &&
              order.typeDelivery.isApplyAddress() &&
              order.typeDelivery.data.cities &&
              order.typeDelivery.data.cities.length > 0 && (
                <SelectCustom
                  ref={inpCity}
                  label={'Ciudad'}
                  value={order ? order.city : undefined}
                  style={{ width: '100%' }}
                  placeholder={'Ciudad'}
                  data={order.typeDelivery.data.cities.map(city => ({ label: city, value: city, item: city }))}
                  showSearch
                  onChange={async value => {
                    //console.log('onChange() ==>', value);
                    await onLoadDistrictByCity({
                      city: value,
                      typeDelivery: order.typeDelivery?.data.value || '',
                      country: countryUser || '',
                      warehouse: order?.warehouse?.data.id || ''
                    });
                    setOrder({ ...order, city: value || '' });
                  }}
                  isRequired={isRequeridUnitManualLoad({
                    typeDelivery: order?.typeDelivery?.data.value,
                    field: 'city',
                    country: user?.data.country
                  })}
                  isIconRequired={
                    !isRequeridUnitManualLoad({
                      typeDelivery: order?.typeDelivery?.data.value,
                      field: 'city',
                      country: user?.data.country
                    })
                  }
                  disabled={isDisabledUnitManualLoad({
                    typeDelivery: order?.typeDelivery?.data.value,
                    field: 'city',
                    country: user?.data.country,
                    cities: order.typeDelivery.data.cities
                  })}
                  notFoundContent={<div>Ciudad sin cobertura de delivery</div>}
                  messageErrorTooltip={
                    Order.isParameterInvalid(orderNew?.data.detail.data.addresses.data.state)
                      ? Order.removeFlag(orderNew?.data.detail.data.addresses.data.state)
                      : undefined
                  }
                  onValidate={isValid => onUpdateValidated({ isValid, type: EnumFielOrder.city })}
                />
              )}

            {order &&
              order.typeDelivery &&
              order.typeDelivery.isApplyAddress() &&
              order.typeDelivery.data.districts &&
              order.typeDelivery.data.districts.length === 0 && (
                <InputCustom
                  ref={inpDistrict}
                  label={nameDistrictForCountry(user?.data.country)}
                  value={order ? order.district : undefined}
                  placeholder={nameDistrictForCountry(user?.data.country)}
                  enterKeyHint='next'
                  isRequired={isRequeridUnitManualLoad({
                    typeDelivery: order?.typeDelivery?.data.value,
                    field: 'district',
                    country: user?.data.country
                  })}
                  isIconRequired={
                    !isRequeridUnitManualLoad({
                      typeDelivery: order?.typeDelivery?.data.value,
                      field: 'district',
                      country: user?.data.country
                    })
                  }
                  disabled={isDisabledUnitManualLoad({
                    typeDelivery: order?.typeDelivery?.data.value,
                    field: 'district',
                    country: user?.data.country
                  })}
                  onChange={event => setOrder({ ...order, district: event.target?.value || '' })}
                  autoComplete='new-password'
                  onPressEnter={() => {
                    if (inpDirection && inpDirection.current !== null) {
                      inpDirection.current.focus();
                    }
                  }}
                  messageErrorTooltip={
                    Order.isParameterInvalid(orderNew?.data.detail.data.addresses.data.district)
                      ? Order.removeFlag(orderNew?.data.detail.data.addresses.data.district)
                      : undefined
                  }
                  onValidate={(isValid, val) => onUpdateValidated({ isValid, value: val, type: EnumFielOrder.district })}
                />
              )}
            {order &&
              order.typeDelivery &&
              order.typeDelivery.isApplyAddress() &&
              order.typeDelivery.data.districts &&
              order.typeDelivery.data.districts &&
              order.typeDelivery.data.districts.length > 0 && (
                <SelectCustom
                  ref={inpDimension}
                  label={nameDistrictForCountry(user?.data.country)}
                  value={order ? order.district : undefined}
                  style={{ width: '100%' }}
                  placeholder={nameDistrictForCountry(user?.data.country)}
                  data={order.typeDelivery.data.districts.map(district => ({ label: district, value: district, item: district }))}
                  showSearch
                  onChange={value => {
                    //console.log('onChange() ==>', value);
                    setOrder({ ...order, district: value || '' });
                  }}
                  isRequired={isRequeridUnitManualLoad({
                    typeDelivery: order?.typeDelivery?.data.value,
                    field: 'district',
                    country: user?.data.country
                  })}
                  isIconRequired={
                    !isRequeridUnitManualLoad({
                      typeDelivery: order?.typeDelivery?.data.value,
                      field: 'district',
                      country: user?.data.country
                    })
                  }
                  disabled={isDisabledUnitManualLoad({
                    typeDelivery: order?.typeDelivery?.data.value,
                    field: 'district',
                    country: user?.data.country
                  })}
                  notFoundContent={<div>{nameDistrictForCountry(user?.data.country)} sin cobertura de delivery</div>}
                  messageErrorTooltip={
                    Order.isParameterInvalid(orderNew?.data.detail.data.addresses.data.district)
                      ? Order.removeFlag(orderNew?.data.detail.data.addresses.data.district)
                      : undefined
                  }
                  onValidate={isValid => onUpdateValidated({ isValid, type: EnumFielOrder.district })}
                />
              )}
            {order && order.typeDelivery && order.typeDelivery.isApplyAddress() && (
              <InputCustom
                ref={inpDirection}
                label='Dirección'
                value={order ? order.direction : undefined}
                placeholder='Dirección'
                onChange={event => setOrder({ ...order, direction: event.target?.value || '' })}
                enterKeyHint='next'
                isRequired={isRequeridUnitManualLoad({ typeDelivery: order?.typeDelivery?.data.value, field: 'direction' })}
                isIconRequired={!isRequeridUnitManualLoad({ typeDelivery: order?.typeDelivery?.data.value, field: 'direction' })}
                disabled={isDisabledUnitManualLoad({ typeDelivery: order?.typeDelivery?.data.value, field: 'direction' })}
                autoComplete='new-password'
                onPressEnter={() => {
                  if (inpNumberStreet && inpNumberStreet.current !== null) {
                    inpNumberStreet.current.focus();
                  }
                }}
                messageErrorTooltip={
                  Order.isParameterInvalid(orderNew?.data.detail.data.addresses.data.adr)
                    ? Order.removeFlag(orderNew?.data.detail.data.addresses.data.adr)
                    : undefined
                }
                onValidate={(isValid, val) => onUpdateValidated({ isValid, value: val, type: EnumFielOrder.adr })}
              />
            )}

            {order && order.typeDelivery && order.typeDelivery.isApplyAddress() && (
              <InputCustom
                ref={inpNumberStreet}
                label='Número Calle'
                value={order ? order.numStreet : undefined}
                placeholder='Número Calle'
                enterKeyHint='next'
                isRequired={isRequeridUnitManualLoad({ typeDelivery: order?.typeDelivery?.data.value, field: 'numStreet' })}
                isIconRequired={!isRequeridUnitManualLoad({ typeDelivery: order?.typeDelivery?.data.value, field: 'numStreet' })}
                disabled={isDisabledUnitManualLoad({ typeDelivery: order?.typeDelivery?.data.value, field: 'numStreet' })}
                onChange={event => setOrder({ ...order, numStreet: event.target?.value || '' })}
                autoComplete='new-password'
                onPressEnter={() => {
                  if (inpAddressDetail && inpAddressDetail.current !== null) {
                    inpAddressDetail.current.focus();
                  }
                }}
                messageErrorTooltip={
                  Order.isParameterInvalid(orderNew?.data.detail.data.addresses.data.number)
                    ? Order.removeFlag(orderNew?.data.detail.data.addresses.data.number)
                    : undefined
                }
                onValidate={(isValid, val) => onUpdateValidated({ isValid, value: val, type: EnumFielOrder.streetNumber })}
              />
            )}

            {order && order.typeDelivery && order.typeDelivery.isApplyAddress() && (
              <InputCustom
                ref={inpAddressDetail}
                label={`Departamento ${
                  isRequeridUnitManualLoad({ typeDelivery: order?.typeDelivery?.data.value, field: 'department' }) ? '' : '(opcional)'
                }`}
                value={order ? order.department : undefined}
                placeholder='Departamento'
                enterKeyHint='next'
                isRequired={isRequeridUnitManualLoad({ typeDelivery: order?.typeDelivery?.data.value, field: 'department' })}
                isIconRequired={!isRequeridUnitManualLoad({ typeDelivery: order?.typeDelivery?.data.value, field: 'department' })}
                disabled={isDisabledUnitManualLoad({ typeDelivery: order?.typeDelivery?.data.value, field: 'department' })}
                onChange={event => setOrder({ ...order, department: event.target?.value || '' })}
                autoComplete='new-password'
                onPressEnter={() => {
                  if (inpItem && inpItem.current !== null) {
                    inpItem.current.focus();
                  }
                }}
                messageErrorTooltip={
                  Order.isParameterInvalid(orderNew?.data.detail.data.addresses.data.department)
                    ? Order.removeFlag(orderNew?.data.detail.data.addresses.data.department)
                    : undefined
                }
              />
            )}
          </FormSessionLayout>

          <FormSessionLayout title='Datos artículo'>
            <InputCustom
              ref={inpItem}
              label='Items'
              type='number'
              value={order ? order.item : undefined}
              placeholder='Item'
              enterKeyHint='next'
              isIconRequired={false}
              isRequired
              validateConstraints={VALIDATE_CONSTRAINTS.onlyNumberPositive}
              onChange={event => setOrder({ ...order, item: event.target?.value || '' })}
              autoComplete='new-password'
              onPressEnter={() => {
                if (inpTotalItems && inpTotalItems.current !== null) {
                  inpTotalItems.current.focus();
                }
              }}
              messageErrorTooltip={
                Order.isParameterInvalid(orderNew?.data.detail.data.shipphing.data.items)
                  ? Order.removeFlag(orderNew?.data.detail.data.shipphing.data.items)
                  : undefined
              }
              onValidate={(isValid, val) => onUpdateValidated({ isValid, value: val, type: EnumFielOrder.items })}
            />
            <InputCustom
              ref={inpTotalItems}
              label='Artículos Comprados'
              type='number'
              value={order ? order.totalItems : undefined}
              placeholder='Artículos Comprados'
              enterKeyHint='next'
              isIconRequired={false}
              isRequired
              validateConstraints={VALIDATE_CONSTRAINTS.onlyNumberPositive}
              onChange={event => setOrder({ ...order, totalItems: event.target?.value || '' })}
              autoComplete='new-password'
              onPressEnter={() => {
                if (inpAmount && inpAmount.current !== null) {
                  inpAmount.current.focus();
                }
              }}
              messageErrorTooltip={
                Order.isParameterInvalid(orderNew?.data.detail.data.shipphing.data.purchasedQuantity)
                  ? Order.removeFlag(orderNew?.data.detail.data.shipphing.data.purchasedQuantity)
                  : undefined
              }
              onValidate={(isValid, val) => onUpdateValidated({ isValid, value: val, type: EnumFielOrder.purchasedQuantity })}
            />
            <Tooltip
              trigger={['hover', 'focus', 'click', 'contextMenu']}
              title={txtPrice}
              overlayClassName='ant-tooltip-content-info'
              overlayInnerStyle={{ backgroundColor: 'white', borderRadius: 5, color: 'black' }}
              placement='bottom'
              color={'white'}
            >
              <InputCustom
                ref={inpAmount}
                label='Precio'
                type='price'
                value={order ? order.amount : undefined}
                placeholder='Precio'
                enterKeyHint='next'
                isRequired
                isIconRequired={false}
                validateConstraints={VALIDATE_CONSTRAINTS.price}
                onChangeNumber={val => setOrder({ ...order, amount: val || 0 })}
                autoComplete='new-password'
                onPressEnter={() => {
                  if (inpDimension && inpDimension.current !== null) {
                    inpDimension.current.focus();
                  }
                }}
                messageErrorTooltip={
                  Order.isParameterInvalid(orderNew?.data.detail.data.shipphing.data.purchasePrice)
                    ? Order.removeFlag(orderNew?.data.detail.data.shipphing.data.purchasePrice)
                    : undefined
                }
                onValidate={(isValid, val) => onUpdateValidated({ isValid, value: val, type: EnumFielOrder.purchasePrice })}
              />
            </Tooltip>
            <SelectCustom
              ref={inpDimension}
              label={`Tamaño ${isRequeridUnitManualLoad({ country: user?.data.country, field: 'dimension' }) ? '' : '(opcional)'}`}
              value={order ? order.dimensions : undefined}
              info={
                <div className='flex flex-row w-full'>
                  <div className='flex flex-col items-end justify-end mr-2'>
                    <h3 className='text-base font-bold'>Tipo de envío</h3>
                    <p className='text-xs font-normal'>Local dentro de la misma ciudad</p>
                  </div>

                  <div className='grid grid-cols-5 gap-1'>
                    <div className='flex flex-col items-center justify-center w-16'>
                      <p className='text-xs font-normal'>Hasta 1Kg.</p>
                      <p className='text-base font-bold'>XS</p>
                    </div>

                    <div className='flex flex-col items-center justify-center w-16'>
                      <p className='text-xs font-normal'>1 a 3 Kg.</p>
                      <p className='text-base font-bold'>S</p>
                    </div>

                    <div className='flex flex-col items-center justify-center w-16'>
                      <p className='text-xs font-normal'>3 a 5 Kg.</p>
                      <p className='text-base font-bold'>M</p>
                    </div>

                    <div className='flex flex-col items-center justify-center w-16'>
                      <p className='text-xs font-normal'>5 a 15 Kg.</p>
                      <p className='text-base font-bold'>L</p>
                    </div>

                    <div className='flex flex-col items-center justify-center w-16'>
                      <p className='text-xs font-normal'>15 a 32 Kg.</p>
                      <p className='text-base font-bold'>XL</p>
                    </div>
                  </div>
                </div>
              }
              style={{ width: '100%' }}
              placeholder='Dimensiones'
              isRequired={isRequeridUnitManualLoad({ country: user?.data.country, field: 'dimension' })}
              isIconRequired={!isRequeridUnitManualLoad({ country: user?.data.country, field: 'dimension' })}
              data={
                dimensions.length > 0
                  ? dimensions.map(dimension => ({ label: dimension.toString(), value: dimension.data.value, item: dimension }))
                  : []
              }
              onChange={value => {
                console.log('onChange() ==>', value);
                setOrder({ ...order, dimensions: value.data.value || '' });
              }}
              messageErrorTooltip={
                Order.isParameterInvalid(orderNew?.data.detail.data.shipphing.data.size)
                  ? Order.removeFlag(orderNew?.data.detail.data.shipphing.data.size)
                  : undefined
              }
              onValidate={isValid => onUpdateValidated({ isValid, type: EnumFielOrder.size })}
            />
          </FormSessionLayout>

          <div className='flex flex-row items-center justify-center mx-4 sm:mx-0 sm:justify-end'>
            <ButtonCustom
              disabled={isAvailable()}
              notStyleDefault={true}
              className='w-full mb-3 sm:w-60 bg-primary'
              onClick={() => onValidateAllInputs()}
            >
              Cargar orden
            </ButtonCustom>
          </div>
        </div>
      </div>
      {/** popup de resumen de la orden **/}
      <RenderPopUp
        isVisible={orderSummary}
        styleContent={{
          minWidth: screenResolution <= SizeScreen.sm ? '60%' : '40%',
          maxHeight: '80%',
          minHeight: screenResolution <= SizeScreen.sm ? '100%' : 'auto'
        }}
        classNameContent={'px-0 mx-auto overflow-y-auto'}
      >
        <div className='px-4 pb-4 border-b border-gray-200'>
          <h1 className='text-xl'>Resumen orden</h1>
        </div>

        <div className='mx-4 mt-4'>
          <AlertMessage
            text={
              <div>
                <strong>Revisa la informacion antes de cargar </strong>
                <br />
                <p className='text-black'>
                  Si necesitas cambiar datos o borrar una orden subida, solo la podrás hacer mediante personal de Arauco Hub.
                </p>
              </div>
            }
            type={TypeAlertMessage.warning}
          />
        </div>

        <div className='flex flex-col justify-center px-4 my-4 sm:flex-row'>
          <div className='flex flex-row items-center justify-center py-4 mr-0 sm:mr-8 sm:flex-col'>
            <div className='flex flex-col items-center justify-center mr-4 sm:mb-6 sm:mr-0'>
              <IconCustom name={NameIcons.inventory} className='text-6xl sm:text-8xl text-primary' />
              <h1 className={'text-xl sm:text-2xl'}>Orden {order?.order}</h1>
            </div>

            <div className='flex flex-col items-center justify-center ml-4 sm:ml-0'>
              <span className='text-primary'>Tipo de envío</span>
              <div className='flex items-center justify-center text-primary'>
                <IconCustom name={stringDeliveryToIcon(order?.typeDelivery?.data.value)} />
                <h1 className='text-xl text-primary'>{order?.typeDelivery?.data.name}</h1>
              </div>
            </div>
          </div>

          <div className={'border-t border-gray-200 w-full sm:border-r sm:w-0'} />

          <div className='flex flex-col items-start justify-start py-4 ml-8'>
            <p className='text-primary'>Datos cliente</p>
            <h1 className='text-xl'>{`${order?.firstName} ${order?.lastName}`}</h1>
            <p>Email: {order?.email}</p>
            {order && order?.direction && (
              <p>Dirección: {`${order?.direction}, ${order?.numStreet}, ${order?.district}, ${order?.city}`}</p>
            )}

            <p className='mt-3 text-primary'>Datos artículo</p>
            <p>Items: {order?.item}</p>
            <p>Artículo: {order?.totalItems}</p>
            {order && order?.dimensions && <p>Tamaño: {order?.dimensions}</p>}

            <p className='mt-3 text-primary'>Precio</p>
            <p>${order?.amount}</p>
          </div>
        </div>

        <div className='flex flex-col justify-between mx-4 sm:flex-row'>
          {screenResolution <= SizeScreen.sm && (
            <ButtonCustom className='mb-4 sm:mb-0' onClick={() => onSubmit()}>
              Cargar orden
            </ButtonCustom>
          )}
          <ButtonCustom styleButton={StyleButton.outlinePrimary} onClick={() => setOrderSummary(false)}>
            Volver a editar
          </ButtonCustom>

          {screenResolution > SizeScreen.sm && <ButtonCustom onClick={() => onSubmit()}>Cargar orden</ButtonCustom>}
        </div>
      </RenderPopUp>
      {/** popup de errores **/}
      <RenderPopUp
        isVisible={orderError}
        styleContent={{
          minWidth: screenResolution <= SizeScreen.sm ? '60%' : '40%',
          maxHeight: '80%',
          minHeight: screenResolution <= SizeScreen.sm ? '100%' : 'auto'
        }}
        classNameContent={'px-0 mx-auto overflow-y-auto'}
      >
        <div className='px-4 pb-4 border-b border-gray-200'>
          <h1 className='text-xl'>Errores encontrados</h1>
        </div>

        <div className='mx-4 mt-4'>
          <AlertMessage
            text={
              <div>
                <strong>Corrige la informacion antes de cargar</strong>
                <br />
              </div>
            }
            type={TypeAlertMessage.warning}
          />
        </div>

        <div className='flex flex-col justify-left my-4 sm:flex-row'>
          <div className='flex flex-col items-start justify-start py-4 ml-8'>
            <ol>
              {messageError.map((e: any, index: number) => (
                <li key={index}>
                  <p className='text-m text-primary font-bold'>{e.path[0].charAt(0).toUpperCase() + e.path[0].slice(1)}</p>
                  <p className='text-redarauco'>{e.message}</p>
                  {index < messageError.length - 1 && <br />}
                </li>
              ))}
            </ol>
          </div>
        </div>

        <div className='flex flex-col justify-center mx-4 sm:flex-row'>
          <ButtonCustom styleButton={StyleButton.outlinePrimary} onClick={() => setOrderError(false)}>
            Volver a editar
          </ButtonCustom>
        </div>
      </RenderPopUp>
      ;
    </MenuSessionContainer>
  );
};

export default UnitManualLoadOrganism;
